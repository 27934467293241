import React from 'react'
import { HOME_SECTIONS } from '../lib/constants'

import { Grid, Header } from 'semantic-ui-react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Book from '../components/book'
import Button from '../components/button'

import './home.css'

const HomePage = () => (
  <Layout>
    <SEO title="Home" />

    <Grid className="home-page" stackable columns={2}>


      <Grid.Column width={6}>
        <Book bookIndex={0} isNew />
      </Grid.Column>

      <Grid.Column width={6}>

        <div className="buttons">
          {HOME_SECTIONS.map(buttonSection => (
            <>
              <Header as="h6">{buttonSection.sectionTitle}</Header>
              {buttonSection.buttons.map(button => (
                <>
                  <Button href={button.href}>
                    {button.title}
                    {button.subtitle && <span>{button.subtitle}</span>}
                  </Button>
                  <br />
                </>
              ))}
            </>
          ))}
        </div>

      </Grid.Column>

    </Grid>

  </Layout >
)

export default HomePage
